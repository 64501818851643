import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// Chakra-Ui
import { useToast } from "@chakra-ui/react";

// MSAL & authentication import
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { graphScopes, powerBiScopes } from "./authConfig";
import { callMsGraph, getUserProfilePhoto } from "./graph";

// Firebase import
import { requestNotificationPermission } from "./firebase";
import { getMessaging, onMessage } from "firebase/messaging";

// CORE General API
import API from "./api/API";

// Global Components
import LandingPage from "./layouts/landingpage/LandingPage";
import TopBar from "./components/global/TopBar";

// Components for Action Items
import ActionItems from "./layouts/actionitems/ActionItems";

// Customer Extension Info
import CustomerExtInfo from "./layouts/customerextinfo/CustomerExtInfo";

// Customer Look Up
import CustomerLookUp from "./layouts/customerlookup/CustomerLookUp";

// Components for Fleet Foundations
import FleetFoundations from "./layouts/fleetfoundations/FleetFoundations";
import FDManage from "./layouts/fleetfoundations/FDManage";
import TrainingCalendar from "./layouts/fleetfoundations/Calendar";

// Driver Assist
import DriverAssist from "./layouts/driverassist/DriverAssist";

// OpenAI Chat
import OpenAIChat from "./layouts/openAI/OpenAiChat";

// Components for Driver Board
import DriverBoardMenu from "./layouts/driverboard/DriverBoardMenu";
import RecruitingDashboard from "./layouts/driverboard/Recruiting";
import OrientationDashBoard from "./layouts/driverboard/Orientation";
import PurchasingDashboard from "./layouts/purchasing/Purchasing";
import OutreachDashboard from "./layouts/outreach/Outreach";
import LeasingDashBoard from "./layouts/driverboard/Leasing";
import TrainerDashBoard from "./layouts/driverboard/TrainersDashboard";
import OperationsDashboard from "./layouts/driverboard/Operations";

// Component for the BRT
import BRTActionItem from "./layouts/brt/BRTActionItem";
import NegativeBalance from "./layouts/negativebalance/NegativeBalance";
import WelcomeCall from "./layouts/welcomecall/WelcomeCall";
import OnboardingCall from "./layouts/onboardingcall/OnboardingCall";

// Component for Business Form
import TerminationForm from "./layouts/forms/TerminationForm";
import StatusChangeForm from "./layouts/forms/StatusChangeForm";
import TDOForm from "./layouts/forms/TDOForm";

const App = () => {
  const toast = useToast();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userTitle, setUserTitle] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [userProfilePhoto, setUserProfilePhoto] = useState<string | null>(null);
  const [powerBiReportToken, setPowerBiReportToken] = useState<string | null>(
    null
  );
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // Fetch user data from Graph API if authenticated
  const fetchProfileData = async () => {
    try {
      const graphTokenResponse = await instance.acquireTokenSilent({
        scopes: graphScopes,
        account: accounts[0],
      });

      const graphResponse = await callMsGraph(graphTokenResponse.accessToken);
      setUserData(graphResponse);

      const userProfilePhoto = await getUserProfilePhoto(
        graphTokenResponse.accessToken
      );
      setUserProfilePhoto(userProfilePhoto);

      const powerBiTokenResponse = await instance.acquireTokenSilent({
        scopes: powerBiScopes,
        account: accounts[0],
      });

      validatePowerBiToken(powerBiTokenResponse);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Set user data from Graph API response
  const setUserData = (graphResponse: any) => {
    setUserName(graphResponse.displayName);
    setUserTitle(graphResponse.jobTitle);
    setUserDepartment(graphResponse.department);
    setUserEmail(graphResponse.mail || graphResponse.userPrincipalName);
  };

  // Validate Power BI token and re-initiate login if needed
  const validatePowerBiToken = (powerBiTokenResponse: any) => {
    if (
      powerBiTokenResponse.scopes &&
      !powerBiScopes.every((scope) =>
        powerBiTokenResponse.scopes.includes(scope)
      )
    ) {
      console.warn(
        "User hasn't given permissions for Power BI. Re-initiating login..."
      );
      instance
        .loginRedirect({ scopes: powerBiScopes })
        .catch((error) => console.error("Login error:", error));
    } else {
      setPowerBiReportToken(powerBiTokenResponse.accessToken);
    }
  };

  // Send Token to be stored in DB
  const subscribeToNotifications = async () => {
    try {
      const token = await requestNotificationPermission();
      if (token) {
        const payload = {
          process: "notification",
          userToken: token,
          department: userDepartment,
          user: userName,
          userEmail: userEmail,
        };
        await API(payload);
        setupNotificationListener();
      }
    } catch (error) {
      console.error("Error retrieving Firebase token:", error);
    }
  };

  // Setup notification listener
  const setupNotificationListener = () => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      toast({
        title: payload.notification?.title || "New Notification",
        description: payload.notification?.body || "",
        status: "info",
        duration: 10000,
        isClosable: true,
      });
    });
  };

  // Check if user is authenticated and redirect to login
  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      try {
        if (inProgress === "none" && !isAuthenticated && !accounts.length) {
          await instance.loginRedirect({ scopes: graphScopes });
        } else if (isAuthenticated && accounts.length) {
          fetchProfileData();
          setupNotificationListener();
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    };

    checkAuthAndRedirect();
  }, [isAuthenticated, instance, inProgress]);

  return (
    <div
      style={{
        background: "linear-gradient(to right, #f8f9fa, #f1f1f1)",
        height: "100vh",
      }}
    >
      <TopBar
        userName={userName}
        userPhoto={userProfilePhoto}
        userNotifications={[]}
        userEmail={userEmail}
        handleSubscribe={subscribeToNotifications}
      />
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/actionitems"
          element={
            <ActionItems
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/actionitems/:drivercode"
          element={
            <ActionItems
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/actionitems/emulate/:department/:user"
          element={
            <ActionItems
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/customer-extension-info/"
          element={
            <CustomerExtInfo
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/customer-lookup/"
          element={
            <CustomerLookUp
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/fleetfoundations"
          element={
            <FleetFoundations
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/fleetfoundations/manage"
          element={
            <FDManage
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/fleetfoundations/scheduled"
          element={<TrainingCalendar />}
        />

        <Route
          path="/driverassist/:ID"
          element={
            <DriverAssist
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/openai"
          element={
            <OpenAIChat
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard"
          element={
            <DriverBoardMenu
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard/recruiting"
          element={
            <RecruitingDashboard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard/orientation"
          element={
            <OrientationDashBoard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard/seating"
          element={
            <LeasingDashBoard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard/trainers"
          element={
            <TrainerDashBoard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/driverboard/operations"
          element={
            <OperationsDashboard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/brt/:ID"
          element={
            <BRTActionItem
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/welcome/:ID"
          element={
            <WelcomeCall
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/onboarding/:ID"
          element={
            <OnboardingCall
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/purchasing"
          element={
            <PurchasingDashboard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/outreach/:ID"
          element={
            <OutreachDashboard
              userName={userName}
              userEmail={userEmail}
              userTitle={userTitle}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/negativebalance/:ID"
          element={
            <NegativeBalance
              userTitle={userTitle}
              userName={userName}
              userEmail={userEmail}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/terminationform"
          element={
            <TerminationForm
              userTitle={userTitle}
              userName={userName}
              userEmail={userEmail}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/statuschangeform"
          element={
            <StatusChangeForm
              userTitle={userTitle}
              userName={userName}
              userEmail={userEmail}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
        <Route
          path="/tdoform"
          element={
            <TDOForm
              userTitle={userTitle}
              userName={userName}
              userEmail={userEmail}
              userDepartment={userDepartment}
              powerBiReportToken={powerBiReportToken}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default App;
