import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  HStack,
  VStack,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
  useDisclosure,
  useDisclosure as userEmulatorDisclosure,
  Text,
} from "@chakra-ui/react";

// Types
import { userInfo } from "../../types/Types";

// React Router
import { useParams } from "react-router-dom";

// API
import expressAPI, { Payload } from "../../api/expressAPI";

// Types
import { UserActionItemsProps } from "../../types/Types";

// Components
import CompanyActionItems from "../../components/powerbi/CompanyActionItems";
import Loading from "../../components/global/Loading";
import SideBar from "../../components/actionitems/SideBar";
import UserActionItems from "../../components/actionitems/ActionItemsTable";
import Emulator from "../../components/actionitems/Emulator";

// Icons
import { GrRefresh } from "react-icons/gr";
import { GoSortDesc } from "react-icons/go";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaUserAstronaut } from "react-icons/fa";

// Claims Stats Interface
interface claimStatsType {
  Email: string | null;
  Preventable: string | null;
  GrossReserve: string | null;
  Injury: string | null;
  ConsecutiveDays: string | null;
}

const ActionItems: React.FC<userInfo> = ({
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  // API End Point
  const endpoint = "core/actionitems/data";

  const [viewState, setViewState] = useState("user");
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState<UserActionItemsProps[]>([]);
  const [filteredData, setFilteredData] = useState<UserActionItemsProps[]>([]);
  const [processes, setProcesses] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<string[]>([]);
  const { drivercode, user, department } = useParams();
  const [sortBy, setSortBy] = useState<"alphabetical" | "count">("count");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEmulatorOpen,
    onOpen: onEmulatorOpen,
    onClose: onEmulatorClose,
  } = userEmulatorDisclosure();

  // useState for the Claims States, with everything started as null
  const [claimStats, setClaimStats] = useState<claimStatsType>({
    Email: null,
    Preventable: null,
    GrossReserve: null,
    Injury: null,
    ConsecutiveDays: null,
  });

  // Function to call the API for the Claims Stats, email came from React's MSAL
  const fetchClaimsStats = async () => {
    try {
      const apiResponse = await expressAPI(endpoint, {
        process: "driver leader stats",
        userEmail,
      });

      // Check if apiResponse has less than 1 records
      if (apiResponse.length < 1) {
        setClaimStats({
          Email: null,
          Preventable: null,
          GrossReserve: null,
          Injury: null,
          ConsecutiveDays: null,
        });
      } else {
        setClaimStats(apiResponse[0] as claimStatsType);
      }
    } catch (error) {}
  };

  // Fetch the Claims Stats
  useEffect(() => {
    if (userEmail) {
      fetchClaimsStats();
    }
  }, [userEmail]);

  // Variables for the filters
  const [selectedAssigneeFilter, setSelectedAssigneeFilter] =
    useState("Assignees");
  const [selectedProcessFilter, setSelectedProcessFilter] =
    useState("Processes");

  // Update the Assignee filter
  const handleAssigneeFilterChange = (value: string) => {
    setSelectedAssigneeFilter(value);
  };

  // Update the Process filter
  const handleProcessFilterChange = (value: string) => {
    setSelectedProcessFilter(value);
  };

  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleViewStateChange = (newViewState: string) => {
    setViewState(newViewState);
  };

  // Function to sort the data

  const handleSortByChange = (value: any) => {
    setSortBy(value);
  };

  const handleSortDirectionChange = (value: any) => {
    setSortDirection(value);
  };

  // Function to fetch data from API with loading screen
  const fetchDataFromAPI = async () => {
    setIsLoading(true);

    try {
      let payload: Payload;

      // Check if both user and department are present in the URL parameters
      if (user && department) {
        payload = {
          process: viewState,
          user,
          department,
        };
      } else {
        // Fall back to userEmail and userDepartment if either is missing
        payload = {
          process: viewState,
          user: userEmail,
          department: userDepartment,
          drivercode: drivercode,
        };
      }

      const apiResponse = await expressAPI(endpoint, payload);
      setData(apiResponse as UserActionItemsProps[]);
    } catch (error) {
      console.error("API request failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to update the data every 60 seconds without loading screen
  const UpdateData = async () => {
    try {
      let payload: Payload;

      // Check if both user and department are present in the URL parameters
      if (user && department) {
        payload = {
          process: viewState,
          user,
          department,
        };
      } else {
        // Fall back to userEmail and userDepartment if either is missing
        payload = {
          process: viewState,
          user: userEmail,
          department: userDepartment,
          drivercode: drivercode,
        };
      }

      const apiResponse = await expressAPI(endpoint, payload);
      setData(apiResponse as UserActionItemsProps[]);
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  // Update the title of the page
  useEffect(() => {
    document.title = "Action Items";
  });

  // Fetch data from API
  useEffect(() => {
    if (userEmail && userDepartment) {
      fetchDataFromAPI();
    }
  }, [userEmail, viewState, userDepartment, drivercode, department]);

  // Update the data every 120 seconds without loading screen
  useEffect(() => {
    if (userEmail && userDepartment) {
      const intervalId = setInterval(UpdateData, 120000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [userEmail, viewState, userDepartment, user, department]);

  // Filtering the data
  useEffect(() => {
    if (!isLoading) {
      const filtered = data.filter((item) => {
        const processFilterMatch =
          selectedProcessFilter === "Processes" ||
          (Array.isArray(selectedProcessFilter)
            ? selectedProcessFilter.includes(item.Process)
            : selectedProcessFilter === item.Process);

        const assigneeFilterMatch =
          selectedAssigneeFilter === "Assignees" ||
          (Array.isArray(selectedAssigneeFilter)
            ? selectedAssigneeFilter.includes(item.Assignee)
            : selectedAssigneeFilter === item.Assignee);

        const searchInputMatch =
          item.Driver?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.DriverCode?.toLowerCase().includes(searchInput.toLowerCase());
        return processFilterMatch && assigneeFilterMatch && searchInputMatch;
      });

      setFilteredData(filtered);
    }
  }, [
    isLoading,
    searchInput,
    data,
    selectedProcessFilter,
    selectedAssigneeFilter,
  ]);

  // Grouping the data
  useEffect(() => {
    if (!isLoading) {
      // Distinct processes
      const distinctProcesses: string[] = Array.from(
        new Set(data.map((item) => item.Process))
      ).sort();
      setProcesses(distinctProcesses);

      // Distinct assignees
      const distinctAssignees: string[] = Array.from(
        new Set(data.map((item) => item.Assignee || ""))
      ).sort();
      setAssignees(distinctAssignees);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (drivercode) {
      setSearchInput(drivercode);
      setViewState("driver");
    } else {
      if (
        userDepartment === "Driver Settlements" ||
        userDepartment === "Leasing Services" ||
        userDepartment === "Human Resources"
      ) {
        setViewState("department");
      } else {
        setViewState("user");
      }
    }
  }, [drivercode, userDepartment]);

  return (
    <Flex height="calc(100vh - 85px)" m="15px">
      <VStack
        width="300px"
        minWidth="300px"
        p="10px"
        mr="15px"
        borderRadius="15px"
        bgColor="rgba(255, 255, 255, 0.5)"
        backdropFilter="blur(12px)"
        shadow="md"
        justifyContent="flex-start"
      >
        <SideBar
          viewState={viewState}
          setSearchValue={handleSearchInputChange}
          processes={processes}
          assignees={assignees}
          selectedAssignee={selectedAssigneeFilter}
          selectedProcess={selectedProcessFilter}
          onAssigneeFilterChange={handleAssigneeFilterChange}
          onProcessFilterChange={handleProcessFilterChange}
          onViewStateChange={handleViewStateChange}
        />
        {claimStats.Email && (
          <VStack
            w="90%"
            h="200px"
            bg="yellow.50"
            borderRadius="xl"
            justifyContent="center"
            alignItems="flex-start"
            shadow="md"
            p="10px"
            backdropFilter="blur(12px)"
            mt="20px"
          >
            <Text fontSize="md" color="blue.600" fontWeight="semibold">
              My Last 6 Months of Safety
            </Text>
            <VStack w="100%" alignItems="flex-start" spacing={2}>
              {" "}
              <Text fontSize="sm" color="gray.600">
                Claims:{" "}
                <Text as="span" fontWeight="semibold">
                  {claimStats.Preventable || "N/A"}
                </Text>
              </Text>
              <Text fontSize="sm" color="gray.600">
                Gross cost:{" "}
                <Text as="span" fontWeight="semibold">
                  {claimStats.GrossReserve
                    ? `$${Number(claimStats.GrossReserve).toLocaleString()}`
                    : "0"}
                </Text>
              </Text>
              <Text fontSize="sm" color="gray.600">
                Work comp claims:{" "}
                <Text as="span" fontWeight="semibold">
                  {claimStats.Injury || "0"}
                </Text>
              </Text>
              <Text fontSize="sm" color="gray.600">
                Consecutive days without a preventable accident:{" "}
                <Text as="span" fontWeight="semibold">
                  {claimStats.ConsecutiveDays || "N/A"}
                </Text>
              </Text>
            </VStack>
          </VStack>
        )}
      </VStack>

      <VStack flex="1" height="100%">
        <HStack
          width="100%"
          height="60px"
          pl="10px"
          pr="10px"
          borderRadius="15px"
          bgColor="rgba(255, 255, 255, 0.5)"
          backdropFilter="blur(12px)"
          shadow="md"
          zIndex="1"
        >
          <ButtonGroup
            variant="ghost"
            spacing="2"
            flex="1"
            size="sm"
            alignItems="center"
            justifyContent="start"
          >
            {/* Compliance Report Modal */}
            <Button
              leftIcon={<HiOutlineDocumentReport />}
              size="md"
              colorScheme="gray"
              borderRadius="15px"
              onClick={onOpen}
            >
              Compliance Report
            </Button>

            <CompanyActionItems
              powerBiReportToken={powerBiReportToken}
              isOpen={isOpen}
              onClose={onClose}
            />
            {/* Emulator Modal */}
            {(userDepartment === "Technology" ||
              userEmail.toLowerCase() === "it.jane@hirschbach.com") && (
              <Button
                leftIcon={<FaUserAstronaut />}
                size="md"
                colorScheme="gray"
                borderRadius="15px"
                onClick={() => {
                  onEmulatorOpen();
                }}
              >
                Emulate
              </Button>
            )}
            <Emulator
              isEmulatorOpen={isEmulatorOpen}
              onEmulatorClose={onEmulatorClose}
            />
          </ButtonGroup>
          <ButtonGroup
            variant="ghost"
            spacing="2"
            width="500px"
            size="sm"
            alignItems="center"
            justifyContent="end"
          >
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                icon={<GoSortDesc color="gray.600" />}
                aria-label="View"
                borderRadius="full"
                variant="solid"
                size="md"
              />
              <MenuList
                minWidth="240px"
                zIndex="10"
                background="whiteAlpha.800"
                backdropFilter="blur(12px)"
              >
                <MenuOptionGroup
                  title="Sort By"
                  defaultValue="count"
                  type="radio"
                  onChange={handleSortByChange}
                >
                  <MenuItemOption value="alphabetical">
                    Alphabetical
                  </MenuItemOption>
                  <MenuItemOption value="count">Count</MenuItemOption>
                  <MenuDivider />
                </MenuOptionGroup>
                <MenuOptionGroup
                  defaultValue="desc"
                  title="Sort"
                  type="radio"
                  onChange={handleSortDirectionChange}
                >
                  <MenuItemOption value="asc">Ascending</MenuItemOption>
                  <MenuItemOption value="desc">Descending</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <IconButton
              aria-label="Refresh"
              borderRadius="full"
              variant="solid"
              size="md"
              icon={<GrRefresh color="gray.600" />}
              onClick={fetchDataFromAPI}
            />
          </ButtonGroup>
        </HStack>
        <Box
          flex="1"
          width="100%"
          p="10px"
          overflowY="hidden"
          borderRadius="15px"
          bgColor="rgba(255, 255, 255, 0.5)"
          backdropFilter="blur(12px)"
          shadow="md"
          height="800px"
        >
          <Flex
            justifyContent="space-evenly"
            alignItems="center"
            height="25px"
            bg="blackAlpha.100"
            borderRadius="20px"
          >
            {/* Render process filter information */}
            {Array.isArray(selectedProcessFilter) &&
              selectedProcessFilter.length > 0 &&
              !selectedProcessFilter.includes("Processes") && (
                <div style={{ color: "gray", fontWeight: "lighter" }}>
                  <span style={{ fontWeight: "normal" }}>Processes:</span>{" "}
                  {selectedProcessFilter.join(", ")}
                </div>
              )}

            {/* Render assignee filter information */}
            {Array.isArray(selectedAssigneeFilter) &&
              selectedAssigneeFilter.length > 0 &&
              !selectedAssigneeFilter.includes("Assignees") && (
                <div style={{ color: "gray", fontWeight: "lighter" }}>
                  <span style={{ fontWeight: "normal" }}>Assignees:</span>{" "}
                  {selectedAssigneeFilter.join(", ")}
                </div>
              )}
          </Flex>

          {isLoading ? (
            <Loading />
          ) : (
            <UserActionItems
              data={filteredData}
              sortBy={sortBy}
              sortDirection={sortDirection}
              userEmail={userEmail}
              userDepartment={userDepartment}
            />
          )}
        </Box>
      </VStack>
    </Flex>
  );
};

export default ActionItems;
