import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { UserActionItemsProps } from "../../types/Types";
import { motion } from "framer-motion";

interface UserActionItemsComponentProps {
  data: UserActionItemsProps[];
  sortBy: "alphabetical" | "count";
  sortDirection: "asc" | "desc";
  userEmail: string;
  userDepartment: string;
}

// Function to format the date
const formatDate = (dateString: string | null): string => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "";
};

//This is an extremely complex function that to be honest,
//I have no idea what's going on even through I wrote it.
//So for the love of god, please don't touch it.
//It's working and that's all that matters.

const UserActionItems: React.FC<UserActionItemsComponentProps> = ({
  data,
  sortBy,
  sortDirection,
  userEmail,
  userDepartment,
}) => {
  const Toast = useToast();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [sortedGroupedDataByProcess, setSortedDataByProcess] = useState<
    Record<string, UserActionItemsProps[]>
  >({});
  const [sortedGroupedDataByDriverCode, setSortedDataByDriverCode] = useState<
    Record<string, UserActionItemsProps[]>
  >({});

  type GroupedData = Record<string, UserActionItemsProps[]>;

  const sortAlphabetically = (a: string, b: string): number => {
    return sortDirection === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  };

  const sortByCount = (
    groupedData: GroupedData,
    a: string,
    b: string
  ): number => {
    const countA = groupedData[a].length;
    const countB = groupedData[b].length;
    return sortDirection === "asc" ? countA - countB : countB - countA;
  };

  const applySorting = (groupedData: GroupedData): GroupedData => {
    const sortedKeys = Object.keys(groupedData);
    if (sortBy === "alphabetical") {
      sortedKeys.sort(sortAlphabetically);
    } else if (sortBy === "count") {
      sortedKeys.sort((a, b) => sortByCount(groupedData, a, b));
    }

    return sortedKeys.reduce((acc: GroupedData, key: string) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});
  };

  useEffect(() => {
    const groupedDataByProcess: GroupedData = data.reduce(
      (acc: GroupedData, item: UserActionItemsProps) => {
        (acc[item.Process] = acc[item.Process] || []).push(item);
        return acc;
      },
      {}
    );

    const groupedDataByDriverCode: GroupedData = data.reduce(
      (acc: GroupedData, item: UserActionItemsProps) => {
        (acc[item.DriverCode] = acc[item.DriverCode] || []).push(item);
        return acc;
      },
      {}
    );

    setSortedDataByProcess(applySorting(groupedDataByProcess));
    setSortedDataByDriverCode(applySorting(groupedDataByDriverCode));
  }, [sortBy, sortDirection, data]);

  // Function to handle row click with additional department & assignee check. Allowing Strategy to be able to open any action items
  const handleRowClick = (
    link: string,
    assigneeEmail: string | null,
    department: string | null
  ): void => {
    window.open(link, "_blank");
  };

  return (
    <Box
      height="98%"
      width="100%"
      overflow="auto"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        <Tabs
          onChange={(index) => setTabIndex(index)}
          index={tabIndex}
          colorScheme="teal"
        >
          <TabList
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              borderBottom: "1px solid blackAlpha.200",
              backdropFilter: "blur(12px)",
              background: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Tab fontWeight="semibold">Processes</Tab>
            <Tab fontWeight="semibold">Drivers</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Accordion allowMultiple>
                {Object.entries(sortedGroupedDataByProcess).map(
                  ([processName, items]) => (
                    <AccordionItem key={processName}>
                      <AccordionButton
                        _expanded={{ bg: "blackAlpha.100" }}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <Flex width="100%" justifyContent="space-between">
                          <Box fontWeight="semibold">{processName}</Box>
                          <Box
                            color="gray.500"
                            borderColor="gray.300"
                            borderWidth="1px"
                            borderRadius="full"
                            padding="2px 8px"
                            fontWeight="normal"
                            width="55px"
                            textAlign="center"
                          >
                            {items.length}
                          </Box>
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel>
                        <Table size="sm">
                          <Thead>
                            <Tr>
                              <Th width="150px">Process</Th>
                              <Th width="100px">Driver Code</Th>
                              <Th width="150px">Driver</Th>
                              <Th width="150px">Assignee</Th>
                              <Th width="100px">Created</Th>
                              <Th width="100px">Last Updated</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {items.map((item, index) => (
                              <Tr
                                key={index}
                                onClick={() =>
                                  handleRowClick(
                                    item.Link,
                                    item.AssigneeEmail,
                                    item.Department
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                }}
                                _hover={{
                                  bg: "blackAlpha.100",
                                  transition:
                                    "background-color 0.3s ease-in-out",
                                }}
                              >
                                <Td>{item.Process}</Td>
                                <Td>{item.DriverCode}</Td>
                                <Td>{item.Driver}</Td>
                                <Td>{item.Assignee}</Td>
                                <Td>{formatDate(item.Created)}</Td>
                                <Td>{formatDate(item.LastUpdated)}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            </TabPanel>
            <TabPanel>
              <Accordion allowMultiple>
                {Object.entries(sortedGroupedDataByDriverCode).map(
                  ([driverCode, items]) => (
                    <AccordionItem
                      key={driverCode}
                      borderBottom="1px solid"
                      borderColor="blackAlpha.200"
                    >
                      <AccordionButton
                        bg="whiteAlpha.500"
                        _expanded={{ bg: "blackAlpha.100" }}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <Flex width="100%" justifyContent="space-between">
                          <Box fontWeight="semibold">{driverCode}</Box>
                          <Box
                            color="gray.500"
                            borderColor="gray.300"
                            borderWidth="1px"
                            borderRadius="full"
                            padding="2px 8px"
                            fontWeight="normal"
                            width="55px"
                            textAlign="center"
                          >
                            {items.length}
                          </Box>
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel>
                        <Table size="sm" colorScheme="blackAlpha">
                          <Thead>
                            <Tr>
                              <Th width="150px">Process</Th>
                              <Th width="100px">Driver Code</Th>
                              <Th width="150px">Driver</Th>
                              <Th width="150px">Assignee</Th>
                              <Th width="100px">Created</Th>
                              <Th width="100px">Due Date</Th>
                              <Th width="100px">Last Updated</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {items.map((item, index) => (
                              <Tr
                                key={index}
                                onClick={() =>
                                  handleRowClick(
                                    item.Link,
                                    item.AssigneeEmail,
                                    item.Department
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                }}
                                _hover={{
                                  bg: "blackAlpha.100",
                                  transition:
                                    "background-color 0.3s ease-in-out",
                                }}
                              >
                                <Td>{item.Process}</Td>
                                <Td>{item.DriverCode}</Td>
                                <Td>{item.Driver}</Td>
                                <Td>{item.Assignee}</Td>
                                <Td>{formatDate(item.Created)}</Td>
                                <Td>{formatDate(item.DueDate)}</Td>
                                <Td>{formatDate(item.LastUpdated)}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </motion.div>
    </Box>
  );
};

export default UserActionItems;
